export const environment = {
  baseUrl: 'https://api-sandbox.payee.tech/app',
  afterLoginPath: '/user',
  production: false,

  cashbook: {
    loginUrl: 'https://cashbook.io/auth/sso/payee',
  },
  ehaz: {
    loginUrl: 'https://teszt.ehaz.hu/login',
  },
  szamlazzhu: {
    url: 'https://www.devszla.hu',
  },

  ARROW_DOWN_ICON: 'arrow-down-black',
  COPY_ICON: 'copy-blue',
  EXTERNAL_LINK_ICON: 'external-link-blue',
  MORE_ICON: 'more-blue',
  PLUS_ICON: 'plus-blue',
  RADIO_CHECKED_ICON: 'radio-checked-blue',
  UPLOAD_CLOUD_ICON: 'upload-cloud',
  CHECKBOX_FILLED_ICON: 'checkbox-filled',
  CHECKMARK_GREEN_CIRCLE_ICON: 'checkmark-darker-green-circle',
  TRASH_ICON: 'trash-red',
  LOGO_ICON: 'payee-logo',

  HELP_URL: 'https://help.payee.tech/hu/',
};
