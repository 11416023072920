import {
  Component,
  HostBinding,
  ViewChild
} from '@angular/core';
import { ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import packageJSON from 'src/../package.json';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  open = true;
  @ViewChild('appVersion') el: ElementRef;
  @HostBinding('class.close') get close() { return !this.open; }

  loading = false;

  get currentLang(): string { return this.translate.currentLang; }
  readonly langs: string[];

  readonly environment = environment;

  get versionNumber() {
    return packageJSON.version;
  }

  constructor(
    private translate: TranslateService,
  ) {
    this.langs = this.translate.getLangs();
  }

  toggleOpen() {
    this.open = !this.open;
  }

  selectLang(lang: string): void {
    this.translate.use(lang);
  }
}
